import { clusterApiUrl } from '@solana/web3.js';
import React, { useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import i18n from './i18n';

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { ConnectionProvider, useWallet, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import ComingSoon from "./ComingSoon";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ProtectedAdminRoute from './components/ProtectedAdminRoute';
import Sidebar from "./components/Sidebar";
import SubHeader from "./components/Subheader";
import FAQModal from "./components/modals/FAQModal";
import HowToPlayModal from "./components/modals/HowToPlayModal";
import ProfileModal from "./components/modals/ProfileModal";
import ReferralModal from "./components/modals/ReferralModal";
import UserProvider from "./context/UserContext";
import { GameProvider } from "./context/GameContext";
import { StatsProvider } from "./context/StatsContext";
import AdminPage from "./pages/AdminPage";
import BetPage from "./pages/BetPage";
import GlobalStats from "./pages/GlobalStats";
import LeaderboardPage from "./pages/LeaderboardPage";
import LoginPage from "./pages/LoginPage";
import PersonalStats from "./pages/PersonalStats";
import ReferralPage from "./pages/ReferralPage";
import WalletNotConnected from "./components/WalletNotConnected";

import '@solana/wallet-adapter-react-ui/styles.css';

export default function App() {
    
    return (
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                <AppWalletProvider>
                    <UserProvider>
                        <StatsProvider>
                            <GameProvider>
                                <Layout />
                            </GameProvider>
                        </StatsProvider>
                    </UserProvider>
                </AppWalletProvider>
            </I18nextProvider>
        </BrowserRouter>
    );
}

function AppWalletProvider({ children }: { children: React.ReactNode }) {
    // Get network from environment variable, default to devnet if not set
    const network = import.meta.env.VITE_SOLANA_NETWORK === 'mainnet' 
        ? WalletAdapterNetwork.Mainnet 
        : WalletAdapterNetwork.Devnet;

    // Use standard Solana endpoints
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    
    const wallets = useMemo(
      () => [
      ],
      [network],
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    {children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
}

const Layout = () => {
    const wallet = useWallet();

    return (
        <div className="page-bg w-screen h-screen flex flex-col overflow-hidden">
            {/* Fixed top: header + subheader */}
            <div className="flex-shrink-0">
                <Header/>
                <SubHeader/>
            </div>

            {/* Main area: sidebar (left) + center body + footer (bottom-right) */}
            <div className="flex flex-1 overflow-hidden">
                <Sidebar/>
                <div className="body-bg flex-1 flex flex-col overflow-hidden">
                    {/* Scrollable area */}
                    <div className="flex-1 overflow-auto">
                        <div className="w-full pb-8">
                            <Switch>
                                {/* Login page is accessible without wallet connection */}
                                <Route path="/login">
                                    <LoginPage />
                                </Route>
                                
                                {/* Protected admin route */}
                                <Route path="/admin">
                                    <ProtectedAdminRoute>
                                        <AdminPage/>
                                    </ProtectedAdminRoute>
                                </Route>
                                
                                {/* Other routes require wallet connection */}
                                {!wallet.connected ? (
                                    <Route path="*">
                                        <WalletNotConnected />
                                    </Route>
                                ) : (
                                    <Switch>
                                        <Route exact path="/">
                                            <BetPage/>
                                        </Route>
                                        <Route path="/lucky-numbers">
                                            <GlobalStats/>
                                        </Route>
                                        <Route path="/leaderboard">
                                            <LeaderboardPage/>
                                        </Route>
                                        <Route path="/personal-stats">
                                            <PersonalStats/>
                                        </Route>
                                        <Route path="/referrals">
                                            <ReferralPage/>
                                        </Route>
                                        <Route path="/coming-soon">
                                            <ComingSoon/>
                                        </Route>
                                    </Switch>
                                )}
                            </Switch>
                        </div>
                    </div>

                    <Footer/>
                </div>
            </div>

            <ProfileModal/>
            <FAQModal/>
            <HowToPlayModal/>
            <ReferralModal/>
        </div>
    );
};

